var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"connectiveId"}},[_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm tài khoản khai thác',"arrayExcel":[],"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideDowload":false,"hideExportFile":false},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.showModalReport,"search":function () {}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataTable || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.code)))])])],1):_vm._e(),(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):_vm._e(),(props.column.field == 'account')?_c('span',[_vm._v(" "+_vm._s(props.row.account)+" ")]):_vm._e(),(props.column.field == 'position')?_c('span',[_vm._v(" "+_vm._s(props.row.position)+" ")]):_vm._e(),(props.column.field == 'unit')?_c('span',[_vm._v(" "+_vm._s(props.row.unit)+" ")]):_vm._e(),(props.column.field == 'address')?_c('span',[_vm._v(" "+_vm._s(props.row.address)+" ")]):_vm._e(),(props.column.field == 'status')?_c('span',[(props.row.status === 'open')?_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"CheckIcon","size":"18"},on:{"click":function($event){return _vm.showModalReport(props.row.id)}}}):_vm._e()],1):_vm._e(),(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.showModalReport(props.row.id)}}}),_c('feather-icon',{staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.currentPage},on:{"pageClick":function () {}}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":function () {}}}),_c('modal-create-account',{attrs:{"id":_vm.modalCreateReport}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }