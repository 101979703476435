var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":"Tài khoản liên kết","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":""},on:{"ok":_vm.handleCreateOccupation,"hidden":_vm.resetState}},[_c('div',{staticClass:"modal-report"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Mã nhân viên "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tên nhân viên "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"city","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tài khoản "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Đơn vị trực thuộc "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.name; },"label":"title","options":[],"placeholder":'Đơn vị trực thuộc'},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true)}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Địa chỉ "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"city","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Chức vụ "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Mở ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }