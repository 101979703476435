<template>
  <b-modal
    :id="id"
    size="lg"
    title="Tài khoản liên kết"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateOccupation"
    @hidden="resetState"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-group>
                <label for="InputHelp">Mã nhân viên <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                  :custom-messages="customCode"
                >
                  <b-form-input
                    id="InputHelp"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-group>
                <label for="InputHelp">Tên nhân viên <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                  :custom-messages="customCode"
                >
                  <b-form-input
                    id="InputHelp"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-group>
                <label for="InputHelp">Tài khoản <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="year"
                  rules="required"
                >
                  <b-form-input
                    id="InputHelp"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-group>
                <label for="InputHelp">Đơn vị trực thuộc <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                  :custom-messages="customCode"
                >
                  <v-select
                    :reduce="title => title.name"
                    label="title"
                    :options="[]"
                    :placeholder="'Đơn vị trực thuộc'"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-group>
                <label for="InputHelp">Địa chỉ <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                  :custom-messages="customCode"
                >
                  <b-form-input
                    id="InputHelp"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-group>
                <label for="InputHelp">Chức vụ <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="year"
                  rules="required"
                >
                  <b-form-input
                    id="InputHelp"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
              >
                Mở
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customCode: {
        required: 'Tên danh mục nghề nghiệp là bắt buộc',
      },
      nameFile: null,
      status: 'not_accepted',
    }
  },
  methods: {
    handleCreateOccupation(bvModalEvt) {
      bvModalEvt.preventDefault()
      // this.$emit('handleCreateOccupation', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.nameFile = null
    },
    inputFile() {
      this.$refs['file-second'].click()
    },
    importFileExcel() {
      this.nameFile = this.$refs['file-second'].files[0].name
    },
  },
}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
